<template>
  <div class="screenContainer">
    <img src="../../assets/zhibobacknew.png" class="backImg" />
    <div class="screenContent">
      <div class="screenLeft">
        <div id="main"></div>
        <div style="background: white; height: 12%">
          <div class="btnGroup">
            <div class="btnItem" @click="muteSelf">
              <img
                src="../../assets/screenIcon/jingyin.png"
                v-if="localMute"
                class="btn"
              />
              <img
                src="../../assets/screenIcon/jingyin1.png"
                v-if="!localMute"
                class="btn"
              />
              <div
                :class="{ txt: true, unchoose: !localMute, choose: localMute }"
              >
                静音
              </div>
            </div>
            <div class="btnItem" @click="publishScreen">
              <img src="../../assets/screenIcon/shipin1.png" class="btn" />
              <div class="txt unchoose">切换视频</div>
            </div>
            <div class="btnItem" @click="openPaint">
              <img src="../../assets/screenIcon/baiban1.png" class="btn" />
              <div class="txt unchoose">打开白板</div>
            </div>
            <div class="btnItem" @click="openMember">
              <img
                src="../../assets/screenIcon/chengyuan.png"
                v-if="showMember"
                class="btn"
              />
              <img
                src="../../assets/screenIcon/chengyuan1.png"
                v-if="!showMember"
                class="btn"
              />
              <div
                :class="{
                  txt: true,
                  unchoose: !showMember,
                  choose: showMember,
                }"
              >
                成员管理
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="screenRight">
        <div class="members" v-if="showMember">
          <div class="memberTxt">会议成员</div>
          <div>
            <div v-for="item in roomMembers" :key="item.userId" class="item">
              <span
                class="type"
                v-if="userNamesMap[item.userId]['type'] === 'student'"
                >学生</span
              >
              <span
                class="type"
                v-if="userNamesMap[item.userId]['type'] === 'educator'"
                >讲师</span
              >
              <span
                class="type"
                v-if="userNamesMap[item.userId]['type'] === 'employee'"
                >助教</span
              >
              {{ userNamesMap[item.userId]["name"] }}
            </div>
          </div>
        </div>
        <div class="chatBoard">
          <div
            v-for="(item, index) in messageContainer"
            :key="index"
            class="messageItem"
          >
            <div class="messageUser">
              <span
                class="type"
                v-if="userNamesMap[item.userId]['type'] === 'student'"
                >学生</span
              >
              <span
                class="type"
                v-if="userNamesMap[item.userId]['type'] === 'educator'"
                >讲师</span
              >
              <span
                class="type"
                v-if="userNamesMap[item.userId]['type'] === 'employee'"
                >助教</span
              >
              {{ userNamesMap[item.userId]["name"] }}：<span
                class="messageContent"
                >{{ item.data }}</span
              >
            </div>
          </div>
        </div>
        <div class="input">
          <el-input
            type="textarea"
            v-model="editMessage"
            placeholder="请输入消息,按回车键发送"
            resize="none"
            @keydown.enter.native="sendMessage"
          ></el-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as QNRTC from "pili-rtc-web";
import { createRoomToken, getUserNames, listRoomUsers } from "../../api/index";
import { getUserId, getProjectCode } from "../../utils/store";

const myRoom = new QNRTC.TrackModeSession();

export default {
  data() {
    return {
      audioDevice: "",
      videoDevice: "",
      audioDevices: [],
      videoDevices: [],
      roomToken: "",
      userList: [],
      roomId: "",
      messageContainer: [],
      editMessage: "",
      userNamesMap: {},
      roomId: this.$route.query.roomId,
      localMute: false,
      showMember: false,
      roomMembers: [],
    };
  },
  async mounted() {
    document.title = "讲座";
    this.getUserNames();
    await this.joinRoom();
    this.roomMembers = myRoom.users;
    // this.listRoom()
  },
  async destroyed() {
    await myRoom.unpublish(
      myRoom.publishedTracks.map((item) => item.info.trackId)
    );
    for (const track of myRoom.publishedTracks) {
      await track.release();
    }
    await myRoom.unsubscribe(
      myRoom.subscribedTracks.map((item) => item.info.trackId)
    );
    myRoom.leaveRoom();
  },
  methods: {
    listRoom() {
      listRoomUsers(this.roomId).then((res) => {
        this.roomMembers = [...this.roomMembers, ...res.data.users];
      });
    },
    getUserNames() {
      getUserNames(getProjectCode()).then((res) => {
        if (res.data.code === 0) {
          this.userNamesMap = res.data.data;
        }
      });
    },
    async joinRoom() {
      let roomToken;
      const result = await this.createRoomToken();
      if (result.code == 0) {
        roomToken = result.data.token;
      } else {
        this.$message(result.msg);
        return;
      }
      try {
        // 加入房间
        await myRoom.joinRoomWithToken(roomToken);
        this.$message({
          message: "房间加入成功",
          type: "success",
        });
        await this.publish();
        // 如果加入房间后发现房间已经有人发布，就订阅他
        this.subscribeTracks(myRoom.trackInfoList);
      } catch (e) {
        console.error(e);
        this.$message(`加入房间失败！ErrorCode: ${e.code || ""}`);
        return;
      }
      myRoom.on("track-add", async (tracks) => {
        this.subscribeTracks(tracks);
      });
      myRoom.on("user-join", (user) => {
        this.roomMembers = myRoom.users;
      });
      myRoom.on("user-leave", (user) => {
        this.roomMembers = myRoom.users;
      });
      myRoom.on("messages-received", (msgData) => {
        this.messageContainer = [...this.messageContainer, ...msgData];
      });
    },
    async publish() {
      let tracks;
      try {
        tracks = await QNRTC.deviceManager.getLocalTracks({
          audio: {
            enabled: true,
          },
        });
      } catch (e) {
        console.error(e);
        this.$message(`采集失败，请检查您的音频视频设备。ErrorCode: ${e.code}`);
        return;
      }

      try {
        // 发布采集流
        await myRoom.publish(tracks);
      } catch (e) {
        console.error(e);
        this.$message(`发布失败，ErrorCode: ${e.code}`);
      }
    },

    async publishScreen() {
      let publishedVideo = myRoom.publishedTracks.filter(
        (item) => item.info.kind === "video"
      );
      await myRoom.unpublish(publishedVideo.map((item) => item.info.trackId));
      for (const track of publishedVideo) {
        await track.release();
      }

      let tracks;
      try {
        tracks = await QNRTC.deviceManager.getLocalTracks({
          screen: {
            enabled: true,
            source: "window",
            bitrate: 600,
            height: 720,
            width: 1280,
            audio: true,
          },
          // audio: {
          //     enabled: true,
          // }
        });
        console.log(11111111111, tracks);
      } catch (e) {
        console.error(e);
        this.$message(`采集失败，请检查您的音频视频设备。ErrorCode: ${e.code}`);
        return;
      }

      try {
        // 发布采集流
        await myRoom.publish(tracks);
      } catch (e) {
        console.error(e);
        this.$message(`发布失败，ErrorCode: ${e.code}`);
      }

      let videos = document.getElementsByTagName("video");
      for (let i of videos) {
        i.remove();
      }
      for (const track of tracks) {
        if (track.info.kind === "video") {
          let main = document.getElementById("main");
          track.play(main, true);
        }
      }

      myRoom.setDefaultMergeStream(1280, 720);
    },
    subscribeTracks(trackInfoList) {
      let infoList = trackInfoList.filter((item) => item.tag === "employee");
      myRoom.subscribe(infoList.map((t) => t.trackId)).then((tracks) => {
        for (const track of tracks) {
          let main = document.getElementById("main");
          track.play(main);
        }
      });
    },
    createRoomToken() {
      return new Promise((resolve, reject) => {
        createRoomToken(this.roomId, getUserId(), "admin").then((res) => {
          resolve(res.data);
        });
      });
    },
    listRoomUsers(e) {
      if (e) {
        if (this.roomId !== "") {
          listRoomUsers(this.room_id).then((res) => {
            console.log(res);
            this.userList = res.data;
          });
        }
      }
    },
    async muteSelf() {
      if (myRoom.publishedTracks.length === 0) {
        return;
      }
      let audioTracks = myRoom.publishedTracks.filter(
        (item) => item.info.kind === "audio"
      );
      let filteredTracks = audioTracks.map((item) => {
        item.info.muted = !this.localMute;
        return item.info;
      });
      try {
        await myRoom.muteTracks(filteredTracks);
        this.localMute &&
          this.$message({
            message: "取消静音",
            type: "success",
          });
        !this.localMute &&
          this.$message({
            message: "已静音",
            type: "success",
          });
        this.localMute = !this.localMute;
      } catch (e) {
        this.$message.warning(`操作失败，请检查是否在房间内`);
      }
    },
    async sendMessage() {
      if (!this.editMessage) {
        this.$message.warning("请输入内容");
        return;
      }
      try {
        await myRoom.sendCustomMessage(this.editMessage);
        let currentTime = new Date();
        let sendedMessage = {
          data: this.editMessage,
          timestamp: currentTime.getTime(),
          type: "normal",
          userId: getUserId(),
        };
        this.messageContainer.push(sendedMessage);
        this.editMessage = "";
      } catch (e) {
        this.$message.warning(`发送失败，请先加入房间`);
      }
    },
    openPaint() {
      let url = this.$router.resolve({
        path: "/paint",
      });
      window.open(url.href, "_blank");
    },
    openMember() {
      this.showMember = !this.showMember;
    },
  },
};
</script>

<style lang="less">
.screenContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .backImg {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
  .screenContent {
    width: 90%;
    height: 80%;
    display: flex;
    .screenLeft {
      width: 74%;
      #main {
        width: 100%;
        height: 88%;
        background: black;
      }
      .btnGroup {
        height: 100%;
        width: 64%;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .btnItem {
          text-align: center;
          cursor: pointer;
          .btn {
            height: 2.4vh;
          }
          .txt {
            font-family: Microsoft YaHei;
            font-weight: bold;
            height: 20%;
            font-size: 1.6vh;
          }
          .choose {
            color: #da4645;
          }
          .unchoose {
            color: #072d76;
          }
        }
      }
    }
    .screenRight {
      width: 26%;
      background: rgb(2, 12, 32);
      border-left: 1px solid black;
      position: relative;
      .members {
        width: 100%;
        height: 100%;
        position: absolute;
        background: rgb(2, 12, 32);
        overflow-y: scroll;
        z-index: 1;
        .memberTxt {
          color: white;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          text-align: center;
          margin-top: 20px;
        }
        .item {
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          margin: 10px auto;
          width: 80%;
        }
        .type {
          padding: 4px 12px;
          background: #fda600;
          font-size: 14px;
          color: white;
          margin-right: 10px;
          border-radius: 6px;
        }
      }
      .chatBoard {
        width: 100%;
        height: 88%;
        overflow-y: scroll;
        .messageItem {
          margin: 2vh;
          .messageUser {
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #fda600;
            font-size: 16px;
            .type {
              padding: 4px 10px;
              background: #fda600;
              font-size: 14px;
              color: white;
              margin-right: 10px;
              border-radius: 6px;
            }
          }
          .messageContent {
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
      .input {
        width: 100%;
        height: 12%;
        .el-textarea {
          height: 100%;
          .el-textarea__inner {
            // background: rgba(2,12,32,0.2);
            height: 100%;
            // border: none;
            border-radius: 0;
          }
        }
        .el-textarea__inner:focus {
          outline: 0;
          border: 1px solid white;
        }
      }
    }
  }
}
.qnrtc-stream-player {
  width: 100% !important;
  /* height: 1080px !important; */
  background: #000;
}
</style>
